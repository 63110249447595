import React from "react"
import { MDBCol, MDBContainer, MDBRow, MDBFooter } from "mdbreact"

const Footer = () => {
  return (
    <MDBFooter
      style={{ backgroundColor: "#989898" }}
      className="font-small lighten-3 pt-4"
    >
      <MDBContainer className="text-center text-md-left">
        <MDBRow className="my-4">
          <MDBCol md="4" lg="4">
            <h5 className="text-uppercase mb-4 font-weight-bold">
              DATELE FIRMEI
            </h5>
            <p>
              SC ENDORFINA CONCEPT SRL
            </p>
            <p>
              CUI: 36330743
            </p>
            <p>
              J12/2738/2016
            </p>
            <p>
              Sediu Social: str. Cuza Voda, nr. 20, Floresti, Jud. Cluj
            </p>
            <p>
              Birou: str. Dimitrie Cantemir nr.19, Cluj-Napoca, Jud. Cluj
            </p>
          </MDBCol>
          <hr className="clearfix w-100 d-md-none" />
          <MDBCol md="2" lg="2" className="ml-auto">

          </MDBCol>
          <hr className="clearfix w-100 d-md-none" />
          <MDBCol md="4" lg="4">
            <h5 className="text-uppercase mb-4 font-weight-bold">
              Contact
            </h5>
            {/* <p>
              <i className="fa fa-home mr-3" /> New York, NY 10012, US
            </p> */}
            <p>
              <i className="fa fa-envelope mr-3" />{" "}
              <a href="mailto:office@endorfinaconcept.ro">office@endorfinaconcept.ro</a>
            </p>
            <p>
              <i className="fab fa-facebook mr-3" />{" "}
              <a href="https://www.facebook.com/endorfinaconcept" >
                Facebook
              </a>
            </p>
            <p>
              <i className="fa fa-phone mr-3" /> 0754 452 988
            </p>
          </MDBCol>
          <hr className="clearfix w-100 d-md-none" />
        </MDBRow>
      </MDBContainer>
      <div className="footer-copyright text-center py-3" style={{ backgroundColor: "#989898" }}>

      </div>
    </MDBFooter>

  )
}

export default Footer
