import React, { useState } from "react"
import {
  MDBNavbar,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavbarToggler,
  MDBCollapse,
  MDBContainer,
  MDBRow,
  MDBCol
} from "mdbreact"
import {AnchorLink} from "gatsby-plugin-anchor-links"
import LogoImage from "../images/logoendorfina.png"

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleCollapse = () => {
    setIsOpen(!isOpen)
  }

  return (
    <MDBNavbar
      id="nav"
      color="grey lighten-5"
      fixed="top"
      dark
      expand="md"
      scrolling
      transparent
    >
      <MDBRow>
        <MDBCol md="2">
          <AnchorLink to ="/#top" >
            <img src={LogoImage} alt="logoImage" className="img-fluid logoImageSize"/>
          </AnchorLink>
        </MDBCol>    
        <MDBNavbarToggler onClick={toggleCollapse} />
        <MDBCollapse id="navbarCollapse3" isOpen={isOpen} navbar>
          <MDBNavbarNav left>
            <MDBNavItem>
              <MDBContainer className="mt-2">
                <AnchorLink className="navstyle"
                  to ="/#despreNoi"
                  offset={() => 100}
                >
                  <b>
                  DESPRE NOI
                  </b>
                </AnchorLink>
              </MDBContainer>
            </MDBNavItem>
            <MDBNavItem>
              <MDBContainer className="mt-2">
                <AnchorLink className="navstyle"
                  offset={() => 100}
                  to="/#servicii"
                >
                  <b>
                  SERVICII
                  </b>
                </AnchorLink>
              </MDBContainer>
            </MDBNavItem>
            <MDBNavItem>
              <MDBContainer className="mt-2">
                <AnchorLink className="navstyle"
                  offset={() => 100}
                  to ="/proiecte"
                >
                  <b>
                  PROIECTE
                  </b>
                </AnchorLink>
              </MDBContainer>
            </MDBNavItem>
            <MDBNavItem>
              <MDBContainer className="mt-2">
                <AnchorLink className="navstyle"
                  offset={() => 100}
                  to ="/echipa"
                >
                  <b>
                  ECHIPA
                  </b>
                </AnchorLink>
              </MDBContainer>
            </MDBNavItem>
            <MDBNavItem>
              <MDBContainer className="mt-2">
                <AnchorLink className="navstyle"
                  offset={() => 100}
                  to ="/#contact"
                >
                  <b>
                  CONTACT
                  </b>
                </AnchorLink>
              </MDBContainer>
            </MDBNavItem>
          </MDBNavbarNav>
        </MDBCollapse>
      </MDBRow>
    </MDBNavbar>
  )
}

export default Navbar
